import 'react-image-crop/dist/ReactCrop.css';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getExerciseList } from '../api/exercise';
import { getLocalSetList } from '../api/set';
import { getLocalUser } from '../api/user';
import { ExerciseComponent } from '../components/ExerciseComponent';
import { Exercise } from '../models/exercise';
import { Set } from '../models/set';
import { CreateExerciseModal } from '../components/modals/CreateExerciseModal';
import { AddExercisesDrawer } from '../components/AddExerciseDrawer';
import { TextScanModal } from '../components/modals/TextScanModal';
import { EditExerciseModal } from '../components/modals/EditExerciseModal';
import { FilterProps } from '../models/filter-props';
import { filter } from '../utils/exercise-filter';
import { AppContext } from '../App';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Container, Grid, InputAdornment, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material';

export const MyExercises = () => {
  let user = getLocalUser();
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [showExerciseAdder, setShowExerciseAdder] = useState(false);
  const [showExerciseCreateModal, setShowExerciseCreateModal] = useState(false);
  const [showTextScanModal, setShowTextScanModal] = useState(false);
  const [textScanResult, setTextScanResult] = useState('');
  const [selectedExercise, setSelectedExercise] = useState<Exercise>();
  const [filterProps, setFilterProps] = useState<FilterProps>();
  const [filteredExercises, setFilteredExercises] = useState<Array<Exercise>>();
  const isSetOwner = () => set?.creatorId === user?.id;

  const [exerciseList, setExerciseList] = useState<Array<Exercise>>();

  const [set, setSet] = useState<Set>();
  const { setId } = useParams();

  useEffect(() => {
    async function initialize() {
      if (!exerciseList) {
        const l = await getExerciseList(setId);
        setExerciseList(l);
      }
    }
    initialize();
  }, []);

  useEffect(() => {
    async function initialize() {
      if (!set) {
        const localSetList = await getLocalSetList();
        const set = localSetList?.find((s) => s.id === setId);
        setSet(set);

        if (set) context.setExtraTitle(' - ' + set?.name);
      }
    }
    initialize();
  }, [set, setId]);

  useEffect(() => {
    if (exerciseList) {
      if (filterProps) setFilteredExercises(filter(exerciseList, filterProps));
      else setFilteredExercises(undefined);
    }
  }, [exerciseList, filterProps]);

  return (
    <>
      <AddExercisesDrawer set={set} show={showExerciseAdder} setShow={setShowExerciseAdder} exerciseList={exerciseList} setExerciseList={setExerciseList} />

      <Container>
        <Paper sx={{ my: 0, zIndex: 1, padding: 1, position: 'sticky', top: { xs: 60, sm: 65 } }}>
          <Stack sx={{ alignContent: 'center', alignItems: 'center' }} direction={'row'}>
            <Button onClick={() => setShowExerciseCreateModal(true)}>New</Button>
            <Button
              onClick={() => {
                setShowExerciseAdder(true);
              }}
            >
              Copy
            </Button>
            <TextField
              label="Filter"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setFilterProps({ ...filterProps, name: e.target.value });
              }}
            />
            <Typography sx={{ textAlign: 'end', mx: 2 }}>Zone</Typography>
            <Select
              value={filterProps?.bodyZoneId !== '' && filterProps?.bodyZoneId ? filterProps?.bodyZoneId : 'any'}
              onChange={(e) => {
                if (e.target.value !== 'any') setFilterProps({ ...filterProps, bodyZoneId: e.target.value as any });
                else setFilterProps({ ...filterProps, bodyZoneId: '' });
              }}
            >
              <MenuItem key={'any'} value={'any'}>
                Any
              </MenuItem>
              {context.bodyZoneList?.map((bz) => (
                <MenuItem key={bz.id} value={bz.id}>
                  {bz.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Paper>
        <TextScanModal
          show={showTextScanModal}
          onHide={(text: string) => {
            setTextScanResult(text);
            setShowTextScanModal(false);
          }}
          setShow={() => {
            setShowTextScanModal(false);
          }}
        />
        <CreateExerciseModal
          set={set}
          showTextScanModal={() => {
            setShowTextScanModal(true);
          }}
          textScanModalResult={textScanResult}
          exerciseCreated={(e) => {
            if (exerciseList) setExerciseList([...exerciseList, e]);
          }}
          show={showExerciseCreateModal}
          setShow={setShowExerciseCreateModal}
        />
        {selectedExercise ? (
          <EditExerciseModal
            setExercise={setSelectedExercise}
            exercise={selectedExercise}
            set={set}
            showTextScanModal={() => {
              setShowTextScanModal(true);
            }}
            textScanModalResult={textScanResult}
            exerciseUpdated={(ex) => {
              if (exerciseList) setExerciseList(exerciseList.map((e) => (e.id === ex.id ? ex : e)));
            }}
          />
        ) : (
          <></>
        )}

        <Grid gap={1} sx={{ my: 1 }} container>
          {filteredExercises
            ? filteredExercises.map((e, i) => (
                <ExerciseComponent
                  timeout={1000}
                  key={e.id}
                  onExerciseDeleted={() => exerciseList?.filter((ex) => ex.id !== e?.id)}
                  onClick={() => (isSetOwner() ? setSelectedExercise(e) : undefined)}
                  exercise={e}
                />
              ))
            : exerciseList?.map((e, i) => (
                <ExerciseComponent
                  timeout={1000}
                  key={e.id}
                  onExerciseDeleted={() => setExerciseList(exerciseList?.filter((ex) => ex.id !== e?.id))}
                  onClick={() => (isSetOwner() ? setSelectedExercise(e) : undefined)}
                  exercise={e}
                />
              ))}
        </Grid>
      </Container>
    </>
  );
};
