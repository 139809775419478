import { useEffect, useState } from 'react';
import { deleteSet, getLocalSetList, setDefaultSetId } from '../api/set';
import { Set } from '../models/set';
import { useNavigate } from 'react-router-dom';
import { getLocalUser, isAdminUser } from '../api/user';
import { DeleteModal } from '../components/modals/DeleteSetModal';
import { CreateSetModal } from '../components/modals/CreateSetModal';
import { CreateSetFromOtherModal } from '../components/modals/CreateSetFromOtherModal';
import { Key } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, Checkbox, Container, FormControlLabel, FormGroup, Grid, Grow, Typography } from '@mui/material';

const filters = [(sets: Array<Set>) => sets.filter((s) => s.creatorId === getLocalUser()?.id)];

const SetRow = (props: SetRowProps) => {
  let user = getLocalUser();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const isSetCreator = () => props.set.creatorId === user?.id;

  const navigate = useNavigate();
  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        nameOfObjectToDelete="Set"
        handleDelete={async () => {
          await deleteSet({ setId: props.set.id || '', deleteExercises: true });
          props.setRefreshSets(true);
          setShowDeleteModal(false);
        }}
      ></DeleteModal>
      <Grid item xs={12} md={4}>
        <Grow in={true} timeout={props.timeout}>
          <Card style={{ minHeight: 150 }} className="h-100">
            <CardContent onClick={() => navigate(`/set-viewer/${props.set.id}`)}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {props.set?.name}
              </Typography>
              {isSetCreator() ? <Key></Key> : <></>}
              <Typography variant="body1">{props.set?.description || 'No Description'}</Typography>
              <Typography variant="caption">Last updated at {props.set.modificationTimestamp ? props.set.modificationTimestamp : 'unknown'}</Typography>
            </CardContent>
            <CardActions>
              {isSetCreator() ? (
                <Button
                  onClick={() => {
                    const ownSetCount = props.setList?.filter((s) => s.creatorId === getLocalUser()?.id).length || 0;

                    if (ownSetCount > 1) {
                      setShowDeleteModal(true);
                    } else {
                      alert('You cannot delete your last set.');
                    }
                  }}
                  size="small"
                >
                  Delete
                </Button>
              ) : (
                <></>
              )}
              {isAdminUser() ? (
                <Button
                  onClick={async () => {
                    await setDefaultSetId(props.set.id || '');
                  }}
                >
                  Set as default
                </Button>
              ) : (
                <></>
              )}
            </CardActions>
          </Card>
        </Grow>
      </Grid>
    </>
  );
};

export const MySets = () => {
  const [setList, setSetList] = useState<Array<Set>>();
  const [filteredSets, setFilteredSets] = useState<Array<Set>>();
  const [refreshSets, setRefreshSets] = useState<boolean>(false);
  const [showCreateSetModal, setShowCreateSetModal] = useState(false);
  const [showCreateSetFromOtherModal, setShowCreateSetFromOtherModal] = useState(false);
  const [filterIndex, setFilterIndex] = useState(-1);

  const user = getLocalUser();

  useEffect(() => {
    async function initialize() {
      if (!setList || refreshSets) {
        const sl = await getLocalSetList(true);
        setSetList(sl);
        setRefreshSets(false);
        const storedFilterIndex = localStorage.getItem('filterIndex');
        if (storedFilterIndex) setFilterIndex(Number(storedFilterIndex));
      }
    }
    initialize();
  }, [setList, refreshSets]);

  useEffect(() => {
    if (setList) {
      if (filterIndex >= 0) setFilteredSets(filters[filterIndex](setList));
      else setFilteredSets(undefined);
      localStorage.setItem('filterIndex', String(filterIndex));
    }
  }, [setList, filterIndex]);

  return (
    <>
      <Container>
        <Box component="section" sx={{ m: 1 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(v) => {
                    if (filterIndex !== 0) setFilterIndex(0);
                    else setFilterIndex(-1);
                  }}
                  checked={filterIndex === 0}
                />
              }
              label="Show only my Sets"
            />
          </FormGroup>
        </Box>
        <Box component="section" sx={{ my: 2 }}>
          <Button onClick={() => setShowCreateSetModal(true)}>Create Set</Button>
          <Button onClick={() => setShowCreateSetFromOtherModal(true)}>Create From other Set</Button>
        </Box>
        <Grid container direction={'row'} spacing={2}>
          {filteredSets
            ? filteredSets.map((s: Set, i) => <SetRow timeout={1000} setRefreshSets={setRefreshSets} set={s} setList={filteredSets} />)
            : setList?.map((s: Set, i) => <SetRow timeout={1000} setRefreshSets={setRefreshSets} set={s} setList={setList} />)}
        </Grid>
        <CreateSetFromOtherModal setRefreshSets={setRefreshSets} show={showCreateSetFromOtherModal} setShow={setShowCreateSetFromOtherModal}></CreateSetFromOtherModal>
        <CreateSetModal setRefreshSets={setRefreshSets} showCreateSetModal={showCreateSetModal} setShowCreateSetModal={setShowCreateSetModal}></CreateSetModal>
      </Container>
    </>
  );
};

interface SetRowProps {
  setList: Array<Set>;
  timeout: number;
  set: Set;
  setRefreshSets: (refresh: boolean) => void;
}
