import { FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { TemplateConfig } from '../models/exercise';

interface ExerciseProps {
  exerciseConfig: TemplateConfig;
  updateExerciseConfig: (exerciseConfig: TemplateConfig) => void;
}

export const ExerciseConfigComponent = (props: ExerciseProps) => {
  return (
    <Grid container>
      <Grid item xs={6} md={4}>
        <TextField
          label={'Weight'}
          multiline
          sx={{ m: 1 }}
          maxRows={5}
          value={props.exerciseConfig.weight}
          onChange={(e) => {
            props.updateExerciseConfig({ ...props.exerciseConfig, weight: !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0 });
          }}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label={'Reps'}
          multiline
          sx={{ m: 1 }}
          maxRows={5}
          value={props.exerciseConfig.repetitions}
          onChange={(e) => {
            props.updateExerciseConfig({ ...props.exerciseConfig, repetitions: !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0 });
          }}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label={'Time'}
          multiline
          sx={{ m: 1 }}
          maxRows={5}
          value={props.exerciseConfig.time}
          onChange={(e) => {
            props.updateExerciseConfig({ ...props.exerciseConfig, time: !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0 });
          }}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <FormControlLabel
          control={
            <Switch
              checked={props.exerciseConfig.useTimer || false}
              onChange={(e) => {
                props.updateExerciseConfig({ ...props.exerciseConfig, useTimer: !props.exerciseConfig.useTimer });
              }}
              name="useTimer"
            />
          }
          label="Use Timer"
        />
      </Grid>
    </Grid>
  );
};
