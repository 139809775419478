import { useEffect, useState } from 'react';
import { listUsers } from '../api/user';
import { User } from '../models/user';
import { CreateUserModal } from '../components/modals/CreateUserModal';
import { useNavigate } from 'react-router-dom';
import { getDefaultSetId as getDefaultSetIdApi } from '../api/set';
import { Button, List, ListItem, Typography } from '@mui/material';

export const Users = () => {
  const [userList, setUserList] = useState<Array<User>>();
  const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false);
  const [defaultSetId, setDefaultSetId] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userList || showCreateUserModal === false) {
      const getUsers = async () => {
        const users = await listUsers();
        setUserList(users);
      };
      getUsers();
    }
    if (!defaultSetId) {
      const getDefaultSetId = async () => {
        const defaultSetId = await getDefaultSetIdApi();
        setDefaultSetId(defaultSetId);
      };
      getDefaultSetId();
    }
  }, [showCreateUserModal]);

  return (
    <>
      <Button
        onClick={() => {
          setShowCreateUserModal(true);
        }}
      >
        Create User
      </Button>

      <Typography variant="h6"> Default Set Id: {defaultSetId ?? 'Not Set'}</Typography>
      <List>
        {userList?.map((u) => {
          return (
            <ListItem onClick={() => navigate(`/user/${u.userId}`)}>
              User:&nbsp;<b>{u.username}</b>&nbsp; Active: {u.active === true ? 'Yes' : 'No'}&nbsp; User Roles: &nbsp;
              {u.roles.map((r) => r + ' ')}&nbsp;
              <Button onClick={() => {}} style={{}}>
                Show Sets
              </Button>
            </ListItem>
          );
        })}
      </List>

      <CreateUserModal showCreateUserModal={showCreateUserModal} setShowCreateUserModal={setShowCreateUserModal} />
    </>
  );
};
