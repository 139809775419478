import { useEffect, useState } from 'react';
import { getMuscleTagList } from '../api/muscle-tag';
import { BodyZone, MuscleTag } from '../models/body-zone';

export function useMuscleTagMap() {
  const [muscleTagList, setMuscleTagList] = useState<Array<MuscleTag>>([]);
  const [muscleTagMap, setMuscleTagMap] = useState<Map<string, MuscleTag>>(new Map<string, MuscleTag>());

  const loadMuscleTagMap = async () => {
    const muscleZoneTags = await getMuscleTagList();
    setMuscleTagList(muscleZoneTags);
    const bodyZoneMap = new Map<string, MuscleTag>();
    muscleZoneTags.forEach((bzt) => bodyZoneMap.set(bzt.id, bzt));
    setMuscleTagMap(bodyZoneMap);
  };

  useEffect(() => {
    loadMuscleTagMap();
  }, []);

  return [muscleTagList, muscleTagMap, loadMuscleTagMap] as const;
}
