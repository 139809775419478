import { useContext, useEffect, useRef, useState } from 'react';

import ReactCrop, { PixelCrop, Crop } from 'react-image-crop';
import { createExercise } from '../../api/exercise';
import { uploadFile } from '../../api/file';
import { Exercise, createEmptyExercise } from '../../models/exercise';
import { ImageFromFile } from '../ImageFromFile';
import { useDebounceEffect } from '../../utils/debounce';
import { imgPreview } from '../../utils/imageRendering';
import { Set } from '../../models/set';
import { ExerciseConfigComponent } from '../ExerciseConfigComponent';
import { validateExercise } from '../../validation/exercise';
import { ErrorComponent } from '../ErrorComponent';
import { Box, Button, Divider, FormControl, FormControlLabel, FormGroup, IconButton, MenuItem, Modal, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { ImageFromServer } from '../ImageFromServer';
import { Close, Photo } from '@mui/icons-material';
import { SimpleTagConfiguration } from '../tags/SimpleTagConfiguration';
import { TagConfiguration } from '../tags/TagConfiguration';
import { modalStyle } from './modalStyle';
import { ExternalLinksComponent } from '../ExternalLinksComponent';

export interface CreateExerciseModalProps {
  set: Set | undefined;
  exerciseCreated: (e: Exercise) => void;
  show: boolean;
  setShow: (b: boolean) => void;
  showTextScanModal: () => void;
  textScanModalResult: string;
}

export const CreateExerciseModal = (props: CreateExerciseModalProps) => {
  const [exercise, setExercise] = useState<Exercise>(createEmptyExercise());
  const [validationErrors, setValidationErrors] = useState<Array<string>>([]);
  const [imageFile, setImageFile] = useState<File | null>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [simpleTagConfiguration, setSimpleTagSelection] = useState<boolean>(true);
  // const [showTagEditModal, setShowTagEditModal] = useState<boolean>(false);

  const clearProps = () => {
    setValidationErrors([]);
    setImageFile(null);
    setExercise(createEmptyExercise());
  };

  useEffect(() => {
    if (!exercise.setId) {
      setExercise({ ...exercise, setId: props.set?.id });
    }
  }, [exercise]);

  useEffect(() => {
    console.log(props.textScanModalResult);
    setExercise({ ...exercise, description: props.textScanModalResult });
  }, [props.textScanModalResult]);

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        imgPreview(imgRef.current, completedCrop);
      }
    },
    100,
    [completedCrop],
  );

  return (
    <Modal
      open={props.show}
      onClose={() => {
        // if (reason && reason === 'backdropClick') return;
        props.setShow(false);
      }}
    >
      <Box sx={{ ...modalStyle }}>
        <Box sx={{ my: 1, display: 'sticky', top: 0 }}>
          <Stack direction={'row'}>
            <Typography>Create Exercise</Typography>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
              <Close onClick={() => props.setShow(false)} />
            </Box>
          </Stack>
        </Box>
        <Stack>
          <ErrorComponent errors={validationErrors} />
          <TextField
            value={exercise?.name}
            placeholder="Title"
            label="Title"
            aria-label="Title"
            aria-describedby="basic-addon1"
            sx={{ my: 1 }}
            onChange={(e) => setExercise({ ...exercise, name: e.target.value })}
          />
          <TextField
            label={'Description'}
            multiline
            sx={{ my: 1 }}
            maxRows={5}
            defaultValue={exercise.description}
            value={exercise?.description}
            onChange={(e) => setExercise({ ...exercise, description: e.target.value })}
          />
          <Button sx={{ my: 1 }} onClick={() => props.showTextScanModal()}>
            Scan Text
          </Button>
          <input
            style={{ display: 'none' }}
            onChange={(e) => setImageFile((e.target as any).files ? (e.target as any).files[0] : null)}
            accept="image/*"
            id="icon-button-file"
            type="file"
          />
          {imageFile ? (
            <>
              <ReactCrop crop={crop} onComplete={(c) => setCompletedCrop(c)} onChange={(c) => setCrop(c)}>
                <ImageFromFile ref={imgRef} thumbnail={true} style={{ maxWidth: 300, maxHeight: 300 }} image={imageFile}></ImageFromFile>
              </ReactCrop>
              <br></br>
              <Button
                onClick={async () => {
                  if (completedCrop && imgRef.current) {
                    const blob = await imgPreview(imgRef.current, completedCrop);
                    const imageFile = new File([blob], 'image.jpeg', {
                      type: blob.type,
                    });
                    setImageFile(imageFile);
                  }
                }}
              >
                Crop
              </Button>
            </>
          ) : (
            <Box sx={{ width: '50%' }}>
              <ImageFromServer sx={{ maxWidth: 300, maxHeight: 300 }} thumbnail={true} alt="" id={''} />
            </Box>
          )}
          <label htmlFor="icon-button-file">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <Photo />
              &nbsp; Change Image
            </IconButton>
          </label>
          <FormControl component="fieldset" variant="standard">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={exercise.active || false}
                    onChange={(e) => {
                      setExercise({ ...exercise, active: exercise.active });
                    }}
                    name="active"
                  />
                }
                label="Active"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={exercise.multiLimb || false}
                    onChange={(e) => {
                      setExercise({ ...exercise, multiLimb: !exercise.multiLimb });
                    }}
                    name="alternate-sides"
                  />
                }
                label="Alternating sides"
              />
            </FormGroup>
          </FormControl>
          <ExerciseConfigComponent
            exerciseConfig={exercise.templateConfig}
            updateExerciseConfig={(config) => {
              setExercise({ ...exercise!, templateConfig: config });
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={simpleTagConfiguration}
                onChange={(e) => {
                  setSimpleTagSelection(!simpleTagConfiguration);
                }}
                name="simpleTag"
              />
            }
            label="Simple Tag Selection"
          />
          {simpleTagConfiguration ? <SimpleTagConfiguration exercise={exercise} setExercise={setExercise} /> : <TagConfiguration exercise={exercise} setExercise={setExercise} />}
        </Stack>
        <ExternalLinksComponent exercise={exercise} setExercise={setExercise} />
        <Divider sx={{ m: 1 }} />
        <Box sx={{ m: 1 }}>
          <Button
            onClick={async () => {
              const errors = validateExercise(exercise);
              setValidationErrors(errors);
              if (errors.length > 0) return;
              props.setShow(false);

              const newExercise = exercise;

              if (imageFile) {
                const fileId = await uploadFile(imageFile);
                newExercise.imageId = fileId;
              }
              try {
                const result = await createExercise(newExercise);
                props.exerciseCreated(result);
              } catch (e) {
                if (e instanceof Error) {
                  alert(JSON.parse(e.message).message);
                }
              }
              clearProps();
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              props.setShow(false);
              clearProps();
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
