import { useEffect, useState } from 'react';
import { getExerciseList, copyExercise } from '../api/exercise';
import { getLocalSetList } from '../api/set';
import { Exercise } from '../models/exercise';
import { ExerciseSmall } from './ExerciseSmall';
import { Set } from '../models/set';
import { FilterProps } from '../models/filter-props';
import { filter } from '../utils/exercise-filter';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Drawer, InputAdornment, MenuItem, Select, Snackbar, Stack, TextField, Typography } from '@mui/material';

export const AddExercisesDrawer = (props: any) => {
  const [exerciseList, setExerciseList] = useState<Array<Exercise>>();
  const [set, setSet] = useState<Set>();
  const [setList, setSetList] = useState<Array<Set>>();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [addedExerciseName, setAddedExerciseName] = useState<string>();
  const [filterProps, setFilterProps] = useState<FilterProps>();
  const [filteredExercises, setFilteredExercises] = useState<Array<Exercise>>();

  const exerciseClicked = async (e: Exercise) => {
    setShowToast(true);
    try {
      const newExercise = await copyExercise({ exerciseId: e.id!, targetSetId: props.set?.id || '' });
      setAddedExerciseName(newExercise.name);
      props.setExerciseList([...props.exerciseList, newExercise]);
    } catch (e) {
      if (e instanceof Error) {
        alert(JSON.parse(e.message).message);
      }
    }
  };

  useEffect(() => {
    async function initialize() {
      if (!setList) {
        const localSetList = await getLocalSetList();
        setSetList(localSetList);
        setSet(localSetList[0]);
      }
    }
    initialize();
  }, [setList]);

  useEffect(() => {
    if (exerciseList) {
      if (filterProps) setFilteredExercises(filter(exerciseList, filterProps));
      else setFilteredExercises(undefined);
    }
  }, [exerciseList, filterProps]);

  useEffect(() => {
    async function initialize() {
      if (set) {
        const l = await getExerciseList(set.id);
        setExerciseList(l);
      }
    }
    initialize();
  }, [set]);

  return (
    <>
      <Drawer anchor="right" open={props.show} onClose={() => props.setShow(false)}>
        <Box>
          <Box sx={{ m: 1 }}>
            <Typography variant="h4">Add Exercises</Typography>
          </Box>
          <Stack direction={'row'} style={{ marginTop: 10, justifyItems: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <Typography style={{ marginRight: 4 }} variant="h5">
              from Set
            </Typography>
            <Select value={set?.name} style={{ zIndex: 1 }} id="input-group-dropdown-1">
              {setList?.map((s) => (
                <MenuItem value={s.name} onClick={() => setSet(s)}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack direction={'row'} style={{ marginTop: 4, justifyContent: 'center', alignContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
            <TextField
              label="Filter"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setFilterProps({ ...filterProps, name: e.target.value });
              }}
            />
          </Stack>
          <Box sx={{ marginTop: 2 }} width={250}>
            {filteredExercises
              ? filteredExercises?.map((e) => <ExerciseSmall onClick={() => exerciseClicked(e)} key={e.id} exercise={e} />)
              : exerciseList?.map((e) => <ExerciseSmall onClick={() => exerciseClicked(e)} key={e.id} exercise={e} />)}
          </Box>
          <Snackbar
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            open={showToast}
            autoHideDuration={2000}
            onClose={() => setShowToast(false)}
            message={`${addedExerciseName} added to Set!`}
          />
        </Box>
      </Drawer>
    </>
  );
};
